import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainSubscriptionModal from "../../components/Modal/mainSubscription";
import { performLogout } from "../../redux/actionCreators/authCreators";

// import { logout } from "../../redux/actions/authActions";

const DefaultLayout = ({ children, user, handleLogout, refreshToken }) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const handlerToggleSidebar = () => setToggleSidebar((value) => !value);

  const location = useLocation();
  const history = useHistory();

  const { pathname: path = "" } = location;
  const arryId = path?.split("/");
  const page = arryId[arryId.length - 1];

  const userData = useSelector((state) => state?.auth?.profileData);
  const isSubscriptionExpire = useSelector(
    (state) => state?.subscriptionExpire?.is_expire
  );
  const { is_subscribed = true } = userData;

  const [show, setShow] = useState(false);

  // this needs to be corrected subscription pop up

  useEffect(() => {
    if (!isSubscriptionExpire && is_subscribed) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [isSubscriptionExpire, is_subscribed]);

  useEffect(() => {
    if (page === "subscribe") {
      setShow(false);
    }
  }, [page]);

  const handleConfirm = () => {
    history.push({
      pathname: "/subscribe",
      search: "?show=true",
      // adding isPromoOrFreeTrial state true for preventing existing user to get free trial
      state: {
        isPromoOrFreeTrial: true,
      },
    });
    setShow(false);
  };
  const handleClose = () => {
    if (!isSubscriptionExpire && isSubscriptionExpire === false) {
      setShow(false);
    }
  };

  return (
    <div className="w-100 layout--default">
      <Header
        title="Dashboard"
        user={user}
        handleLogout={() => handleLogout({ refresh_token: refreshToken })}
        handlerToggleSidebar={handlerToggleSidebar}
        toggleSidebar={toggleSidebar}
      />
      <Sidebar
        toggleSidebar={toggleSidebar}
        handlerToggleSidebar={handlerToggleSidebar}
      />
      {children}
      <MainSubscriptionModal
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        show={show}
        heading={"Your Free Trial Has Expired"}
        confirmName={"Select a Plan"}
        para={"Please subscribe to continue hosting challenges for your squads"}
        logo
      />
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  refreshToken: state.auth?.token?.refresh?.token,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: (payload) => dispatch(performLogout(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
