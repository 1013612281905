import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import AUTH_TYPES from "../types/authTypes";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import logger from "redux-logger";
import appReducer from "../reducers/rootReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "subscription"],
};

const rootReducer = (state, action) => {
  if (action.type === AUTH_TYPES.LOGOUT_SUCCESS) {
    storage.removeItem("persist:root");
    localStorage.clear();
    return appReducer({}, action);
  }

  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);
let middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
  window.console.log = () => null;
  window.console.warn = () => null;
  window.console.info = () => null;
  window.console.error = () => null;
}
// export const store = createStore(pReducer, applyMiddleware(thunk, logger));
export const store = createStore(
  pReducer,
  compose(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);
