import AUTH_TYPES from "../types/authTypes";

export const resetState = () => ({
  type: AUTH_TYPES.RESET_STATE,
});

export const setauthentication = () => ({
  type: AUTH_TYPES.AUTHENTICATION_SET,
});

export const updateUserData = (data) => ({
  type: AUTH_TYPES.UPDATE_USER_DATA,
  payload: data,
});

export const loginBegin = () => ({
  type: AUTH_TYPES.LOGIN_BEGIN,
});

export const loginSuccess = (data) => ({
  type: AUTH_TYPES.LOGIN_SUCCESS,
  payload: data,
});

export const loginSteps = (data) => ({
  type: AUTH_TYPES.LOGIN_STEPS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: AUTH_TYPES.LOGIN_FAILURE,
  payload: { error },
});

export const registerBegin = () => ({
  type: AUTH_TYPES.REGISTER_BEGIN,
});

export const registerSuccess = (data) => ({
  type: AUTH_TYPES.REGISTER_SUCCESS,
  payload: data,
});

export const registerFailure = (error) => ({
  type: AUTH_TYPES.REGISTER_FAILURE,
  payload: { error },
});

export const refreshTokenSuccess = (data) => ({
  type: AUTH_TYPES.REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const changePasswordBegin = () => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_BEGIN,
});

export const changePasswordSuccess = () => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (error) => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_FAILURE,
  payload: { error },
});

export const setSubmitting = (data) => ({
  type: AUTH_TYPES.SET_SUBMITTING,
  payload: data,
});

// export const logout = () => ({
//   type: AUTH_TYPES.LOGOUT,
// });

export const logoutBegin = () => ({
  type: AUTH_TYPES.LOGOUT_BEGIN,
});

export const logoutSuccess = () => ({
  type: AUTH_TYPES.LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: AUTH_TYPES.LOGOUT_FAILURE,
  payload: { error },
});

export const getProfileDetailsBegin = () => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_BEGIN,
});

export const getProfileDetailsSuccess = (data) => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_SUCCESS,
  payload: data,
});

export const getProfileDetailsFailure = (error) => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_FAILURE,
  payload: { error },
});

export const emailVerificationBegin = () => ({
  type: AUTH_TYPES.EMAIL_VERIFICATION_BEGIN,
});

export const emailVerificationSuccess = (data) => ({
  type: AUTH_TYPES.EMAIL_VERIFICATION_SUCCESS,
  payload: data,
});

export const emailVerificationFailure = (error) => ({
  type: AUTH_TYPES.EMAIL_VERIFICATION_FAILURE,
  payload: { error },
});

export const getResentEmailBegin = () => ({
  type: AUTH_TYPES.EMAIL_VERIFICATION_BEGIN,
});

export const getResentEmailSuccess = (data) => ({
  type: AUTH_TYPES.EMAIL_VERIFICATION_SUCCESS,
  payload: data,
});

export const getResentEmailFailure = (error) => ({
  type: AUTH_TYPES.EMAIL_VERIFICATION_FAILURE,
  payload: { error },
});

export const socialLoginBegin = () => ({
  type: AUTH_TYPES.SOCIAL_LOGIN_BEGIN,
});

export const socialLoginSuccess = (data) => ({
  type: AUTH_TYPES.SOCIAL_LOGIN_SUCCESS,
  payload: data,
});

export const socialLoginFailure = (error) => ({
  type: AUTH_TYPES.SOCIAL_LOGIN_FAILURE,
  payload: { error },
});

// local state
export const accountIsNotVerifiedSuccess = () => ({
  type: AUTH_TYPES.ACCOUNT_NOT_VERIFIED_SUCCESS,
});
export const accountIsNotVerifiedReset = () => ({
  type: AUTH_TYPES.ACCOUNT_NOT_VERIFIED_RESET,
});
