export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL_WITHOUT_HOST = process.env.REACT_APP_API_BASE_URL_HOST;
export const APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const CLIENT_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const ENDPOINTS = {
  // Auth
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  REFRESH_TOKEN: "auth/refresh-token",
  FORGOT_PASSWORD: "auth/forgot-password",
  RESET_PASSWORD: "auth/reset-password",
  GET_PROFILE: "profile",
  REGISTER: "auth/register",
  ONBOARDING: (step) => `auth/onboarding/${step}`,
  EMAIL_VERIFICATION: (id) => `auth/email-verification/${id}`,
  GET_RESEND_VERIFICATION: "auth/resend-verification-link",
  SOCIAL_LOGIN: "auth/social-login",

  // ACCOUNT
  UPDATE_PROFILE: "profile",
  UPDATE_PASSWORD: "profile/change-password",

  // MANAGE PLAYERS AND SQUAD
  GET_PLAYERS: ({ page, per_page, search, user_type, squad_id }) =>
    `player?page=${page}&per_page=${per_page}${
      search ? `&search=${search}` : ""
    }${user_type ? `&user_type=${user_type}` : ""}${
      squad_id ? `&squad_id=${squad_id}` : ""
    }`,
  // GET_SQUADS: 'squad',
  GET_SQUADS: ({ page, per_page, name }) =>
    `squad?page=${page}&per_page=${per_page}${name ? `&name=${name}` : ""}`,

  // squad create,details and delete
  CREATE_SQUAD: "squad",
  SQUAD_DETAIL: (id) => `squad/${id}`,
  SQUAD_CHALLENGES: `squad/challenges`,
  SQUAD_PLAYER_LIST: (id) => `squad/${id}/players`,
  DELETE_SQUAD: (id) => `squad/${id}`,
  FILE_UPLOAD: "utility/upload-file",
  UPDATE_SQUAD: (id) => `squad/${id}`,
  SQUAD_LIST:
    "/squad?order_by=created_at&is_ascending=false&page=1&per_page=200",
  WIN_THE_DAY: (id) =>
    `/challenge/${id}/won-the-day?order_by=created_at&is_ascending=false&page=1&per_page=1000`,

  //  PLAYER
  CREATE_PLAYER: "player/invitation",
  UPDATE_PLAYER: "player/update-tag",
  PLAYER_DETAIL: (id) => `player/${id}`,
  DELETE_PLAYER: (id) => `player/${id}`,

  // Challenges
  GET_CHALLENGES: ({
    isActive,
    isCompleted,
    isCreatedByMe,
    page,
    per_page,
    resilience,
  }) =>
    `challenge?page=${page}&per_page=${per_page}${
      isActive || isActive === false ? `&is_active=${isActive}` : ""
    }${
      isCompleted || isCompleted === false ? `&is_completed=${isCompleted}` : ""
    }${
      isCreatedByMe || isCreatedByMe === false
        ? `&is_created_by_me=${isCreatedByMe}`
        : ""
    }${resilience !== undefined ? `&resilience=${resilience}` : ""}`,
  CHALLENGE_DETAIL: (id) => `challenge/${id}`,
  CHALLENGE_DELETE: (id) => `challenge/${id}`,
  CREATE_CHALLENGES: `challenge`,
  GET_SB_CHALLENGE: `library/challenge`,
  GET_SUGGESTIONS: "/challenge/suggestion",
  GET_CHALLENGE_PLAYERS: (id) => `challenge/${id}/players`,

  //Analysist
  GET_REPORT: (id) => `/report/${id}`,

  //Subscription & payment
  SUBSCRIPTION_PLAN: ({ host_id }) =>
    `/subscription/plans?user_type=host&host_id=${host_id}`,
  SUBSCRIPTION_PURCHASE: `/subscription/purchase`,
  APPLY_PROMO_CODE: `/subscription/apply-promo-code`,
  APPLY_FREE_TRIAL: `/subscription/free-trial`,
  CHECK_SUBSCRIPTION_STATUS: `/subscription/check-status`,
  SUBSCRIPTION_UPDATE: `/subscription/upgrade-subscription`,
  HOST_SUBSCRIPTION_PLAN: () => `/subscription`,
  HOST_SUBSCRIPTION_ACTIVE: () => `/subscription/active-subscription`,
  HOST_SUBSCRIPTION_CANCEL: () => `/subscription/cancel-subscription`,
  DELETE_ACCOUNT: () => "/profile/account",

  //Library Acrivriry
  GET_LIBRARAY_ACTIVITY: (data) => {
    const API = data?.search
      ? `/library/activity?type=${data?.type}&search=${data?.search}&page=${data?.page}&per_page=${data?.cardNo}`
      : `/library/activity?type=${data?.type}&page=${data?.page}&per_page=${data?.cardNo}`;
    return API;
  },

  ADD_LIBRARY_CHALLENGES: (id) => `/challenge/${id}/activity`,
  RE_ORDER_ACTIVITY: (id) => `/challenge/${id}/sequential-order`,
  GET_CHALLENGES_OVERVIEW_DATA: (id) => `/challenge/${id}`,
  GET_DELETE_ACTIVITY_DATA: (data) =>
    `/challenge/${data?.challenge_id}/activity/${data?.id}`,
  UPDATE_LIBRARY_CHALLENGES: (data) =>
    `/challenge/${data?.challenge_id}/activity/${data?.id}`,
  ADD_HOST_DATA: (id) => `/challenge/${id}/hosting`,

  //Invitation
  SEND_INVITATIONS: (id) => `/challenge/${id}/send-invitation`,
};
