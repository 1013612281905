import AUTH_TYPES from "../types/authTypes";

const initialState = {
  submitting: false,
  resendingOTP: false,
  onboardingStatus: false,
  isAuthenticated: false,
  resetPasswordSubmit: false,
  error: null,
  user: null,
  role: null,
  token: null,
  profileData: null,
  data: null,
  socialData: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_TYPES.RESET_STATE:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case AUTH_TYPES.LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        profileData: action.payload.user,
      };

    case AUTH_TYPES.LOGIN_STEPS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: false,
        user: action.payload.user,
        token: action.payload.token,
        profileData: action.payload.user,
        isAccountNotVerified: false,
      };

    case AUTH_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
        loginOtpSent: false,
        error:
          action.payload && action.payload.error
            ? action.payload.error
            : action?.payload?.message
            ? action.payload?.message
            : null,
      };

    case AUTH_TYPES.REGISTER_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        submitting: false,
        onboardingStatus: action.payload.user.onboarding_step,
        user: action.payload.user,
        token: action.payload.token,
        profileData: action.payload.user,
      };

    case AUTH_TYPES.REGISTER_FAILURE:
      return {
        ...state,
        submitting: false,
        loginOtpSent: false,
      };

    case AUTH_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };

    case AUTH_TYPES.CHANGE_PASSWORD_BEGIN:
      return {
        ...initialState,
        resetPasswordSubmit: true,
      };

    case AUTH_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSubmit: false,
        data: action.payload,
      };

    case AUTH_TYPES.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordSubmit: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.SET_SUBMITTING:
      return {
        ...state,
        submitting: action.payload,
      };

    case AUTH_TYPES.LOGOUT_BEGIN:
      return {
        ...state,
        submitting: true,
      };
    case AUTH_TYPES.LOGOUT_SUCCESS:
      return initialState;

    case AUTH_TYPES.LOGOUT_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        profileData: action.payload,
        user: action.payload,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.AUTHENTICATION_SET:
      return {
        ...state,
        submitting: false,
        isAuthenticated: true,
      };

    case AUTH_TYPES.EMAIL_VERIFICATION_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case AUTH_TYPES.SOCIAL_LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        socialData: action.payload,
        error: null,
      };

    case AUTH_TYPES.SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.ACCOUNT_NOT_VERIFIED_SUCCESS:
      return {
        ...state,
        isAccountNotVerified: true,
      };
    case AUTH_TYPES.ACCOUNT_NOT_VERIFIED_RESET:
      return {
        ...state,
        isAccountNotVerified: false,
      };

    default:
      return state;
  }
}
