import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import Avatar from "react-avatar";
import { Container, Navbar, Nav } from "react-bootstrap";
import DropdownMenu from "../DropdownMenu";
import backArrow from "../../assets/images/headerBack.svg";
import { getImageUrl } from "../../services/utils";
import { ReactComponent as Logout } from "../../assets/images/log-out.svg";
import { ReactComponent as ChangePassword } from "../../assets/images/profileIcon.svg";
import { FaBars } from "react-icons/fa";

const Header = ({
  handleLogout,
  user,
  handlerToggleSidebar,
  toggleSidebar,
}) => {
  const history = useHistory();
  const location = useLocation();
  const paths = location?.pathname.split("/");
  let lastWord = paths[paths?.length - 1].split("-").join(" ");
  let checkNumber = isNaN(lastWord);
  const currentPage = checkNumber
    ? lastWord
    : paths[paths?.length - 2].split("-").join(" ");

  const [arrow, setArrow] = useState(true);

  useEffect(() => {
    if (
      currentPage === "dashboard" ||
      currentPage === "library" ||
      currentPage === "squads" ||
      currentPage === "account" ||
      currentPage === "active challenges" ||
      currentPage === "" ||
      currentPage === "analytics"
    ) {
      setArrow(false);
    } else {
      setArrow(true);
    }
  }, [currentPage, location]);

  const handleBack = () => {
    history.goBack();
  };
  let currentTitle = "";

  switch (currentPage) {
    case "add player":
      currentTitle = "Add New Players";
      break;
    case "add squad":
      currentTitle = "Create Squad";
      break;
    case "player":
      currentTitle = "Player Information";
      break;

    case "squad":
      currentTitle = "Squad Details";
      break;

    case "squads":
      currentTitle = "Current Players";
      break;
    case "players list":
      currentTitle = "Edit Squad";
      break;
    case "library":
      currentTitle = "Select Challenge";
      break;
    case "active challenges":
      currentTitle = "Monitor Progress";
      break;
    case "challenge detail":
      currentTitle = "Monitor Progress";
      break;
    case "analytics":
      currentTitle = "Squad Analytics";
      break;

    case "profile settings":
      currentTitle = "Profile";
      break;
    case "account":
      currentTitle = "Settings";
      break;

    case "grade challenge":
      currentTitle = "Players";
      break;

    default:
      currentTitle = currentPage;
  }

  if (history.location.search === "?squad") {
    currentTitle = "Current Squads";
  } else if (history.location.search === "?by-host") {
    currentTitle = "Created By Me";
  }

  return (
    <Navbar
      sticky="top"
      className={`header ${toggleSidebar && "open"}`}
      style={{
        border: "1px solid #EEEEEE",
        filter: " drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04))",
      }}
    >
      {" "}
      <FaBars
        className="header__menu"
        size={26}
        onClick={() => handlerToggleSidebar()}
      />
      <Container fluid className="px-xl-4 px-lg-2">
        {currentPage === "dashboard" ||
        currentPage === "library" ||
        currentPage === "squads" ||
        currentPage === "active challenges" ||
        currentPage === "" ||
        currentPage === "analytics" ? (
          <div className="text-capitalize fs-18 fw-600 ms-xl-4 ms-lg-0 ps-1">
            {currentTitle}
          </div>
        ) : (
          <div className="header-ml d-flex">
            {arrow && (
              <div className="header-back pointer" onClick={() => handleBack()}>
                <img src={backArrow} alt="arrow" />
              </div>
            )}
            <div className="text-capitalize fs-18 fw-600">{currentTitle}</div>
          </div>
        )}

        <Nav className="ms-auto me-4 pe-1">
          <DropdownMenu
            menu={[
              {
                label: (
                  <div
                    className="d-flex pb-2 w-100 justify-content-between align-items-center"
                    style={{
                      borderBottom: "0.4px solid rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <span>Settings</span>
                    <span>
                      <ChangePassword />
                    </span>
                  </div>
                ),
                onClick: () => history.push("/account"),
              },
              {
                label: (
                  <div className="d-flex  w-100 justify-content-between text-black align-items-center">
                    <span>Logout</span>
                    <span>
                      <Logout />
                    </span>
                  </div>
                ),
                onClick: () => handleLogout(),
              },
            ]}
            style={{
              button: {
                padding: 0,
              },
              menu: {
                minWidth: "12rem",
                marginTop: "0.5rem",
                borderRadius: "12px",
                boxShadow: "none",
                fontWeight: 600,
              },
            }}
            icon={
              <div className="d-flex justify-content-center align-items-center">
                <Avatar
                  src={getImageUrl(user?.profile_image || "")}
                  name={`${user?.first_name} `}
                  size="40"
                  className="ff-primary"
                  style={{
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                />
                {/* <div className="d-flex ms-2 header-details flex-column  text-start">
                  <p
                    className="fw-600 fs-16 text-capitalize"
                    style={{
                      color: "#222222",
                      lineHeight: "20px",
                      letterSpacing: "0.2px",
                    }}
                  >{`${user?.first_name} ${user?.last_name}`}</p>
                </div> */}
              </div>
            }
          />
        </Nav>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

Header.defaultProps = {};

export default Header;
