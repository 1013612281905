import SUBSCRIPTION_TYPE from "../types/subscrption&paymentTypes";

export const getSubscriptionPlanBegin = () => ({
  type: SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_BEGIN,
});

export const getSubscriptionPlanSuccess = (data) => ({
  type: SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const getSubscriptionPlanFailure = (error) => ({
  type: SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_FAILURE,
  payload: { error },
});

export const getSubscriptionPurchaseBegin = (error) => ({
  type: SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_PURCHASE_BEGIN,
  payload: { error },
});

export const getSubscriptionPurchaseSuccess = (data) => ({
  type: SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_PURCHASE_SUCCESS,
  payload: { data },
});

export const getSubscriptionPurchaseFailure = (error) => ({
  type: SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_PURCHASE_FAILURE,
  payload: { error },
});

export const subscriptionUpdateBegin = (error) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_UPDATE_BEGIN,
  payload: { error },
});

export const subscriptionUpdateSuccess = (data) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_UPDATE_SUCCESS,
  payload: { data },
});

export const subscriptionUpdateFailure = (error) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_UPDATE_FAILURE,
  payload: { error },
});

export const subscriptionPlanBegin = (error) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_BEGIN,
  payload: { error },
});

export const subscriptionPlanSuccess = (data) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_SUCCESS,
  payload: { data },
});

export const subscriptionPlanFailure = (error) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_FAILURE,
  payload: { error },
});

export const deleteAccountBegin = (error) => ({
  type: SUBSCRIPTION_TYPE.DELETE_ACCOUNT_BEGIN,
  payload: { error },
});

export const deleteAccountSuccess = (data) => ({
  type: SUBSCRIPTION_TYPE.DELETE_ACCOUNT_SUCCESS,
  payload: { data },
});

export const deleteAccountFailure = (error) => ({
  type: SUBSCRIPTION_TYPE.DELETE_ACCOUNT_FAILURE,
  payload: { error },
});

export const subscriptionExpireSuccess = () => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_EXPIRED_SUCCESS,
});
export const subscriptionExpireReset = () => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_EXPIRED_RESET,
});

export const subscriptionCancelBegin = (error) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_CANCEL_BEGIN,
  payload: { error },
});

export const subscriptionCancelSuccess = () => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_CANCEL_SUCCESS,
});
export const subscriptionCancelFailure = () => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_CANCEL_FAILURE,
});

export const activeSubscriptionBegin = (error) => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_ACTIVE_BEGIN,
  payload: { error },
});

export const activeSubscriptionSuccess = () => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_ACTIVE_SUCCESS,
});
export const activeSubscriptionFailure = () => ({
  type: SUBSCRIPTION_TYPE.SUBSCRIPTION_ACTIVE_FAILURE,
});

