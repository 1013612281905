import LIBRARY_ACTIVITY_TYPES from "../types/libraryTypes";

const initialState = {
  submitting: false,
  error: null,
  data: null,
  challegeData: {},
  overviewData: {},
  deleteData: null,
  invitation: null,
};

export default function analysistReducer(state = initialState, action) {
  switch (action.type) {
    case LIBRARY_ACTIVITY_TYPES.LIBRARY_ACTIVITY_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_ACTIVITY_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_ACTIVITY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case LIBRARY_ACTIVITY_TYPES.LIBRARY_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        challegeData: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case LIBRARY_ACTIVITY_TYPES.CHALLENGES_OVERVIEW_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.CHALLENGES_OVERVIEW_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        overviewData: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.CHALLENGES_OVERVIEW_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case LIBRARY_ACTIVITY_TYPES.DELETE_ACTIVTIY_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.DELETE_ACTIVTIY_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        deleteData: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.DELETE_ACTIVTIY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case LIBRARY_ACTIVITY_TYPES.HOST_SAVE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.HOST_SAVE_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        hostData: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.HOST_SAVE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case LIBRARY_ACTIVITY_TYPES.SEND_INVITATIONS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.SEND_INVITATIONS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        invitation: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.SEND_INVITATIONS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_REORDER_ACTIVITY_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_REORDER_ACTIVITY_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload.data,
      };

    case LIBRARY_ACTIVITY_TYPES.LIBRARY_REORDER_ACTIVITY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
