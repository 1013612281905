import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import accountReducer from "./accountReducer";
import playerSquadReducer, {
  squadAddPlayerReducer,
} from "./playerSquadReducer";
import challengesReducer, {
  hostedChallengesReducer,
  savedChallengesReducer,
} from "./challengesReducer";
import analysistReducer from "./analysistReducer";
import subscriptionReducer, { subscriptionExpireReducer } from "./subscription";
import libraryReducer from "./libraryReducer";

const appReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  account: accountReducer,
  player: playerSquadReducer,
  squadAddPlayer: squadAddPlayerReducer,
  challenge: challengesReducer,
  hostedChallenges: hostedChallengesReducer,
  savedChallenges: savedChallengesReducer,
  analysist: analysistReducer,
  subscription: subscriptionReducer,
  subscriptionExpire: subscriptionExpireReducer,
  library: libraryReducer,
});

export default function rootReducer(state, action) {
  if (action.type === "CLEAR_STORAGE") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");
    localStorage.clear();
    state = {};
  }
  return appReducer(state, action);
}
