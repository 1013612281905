import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as LogoImg } from "../../assets/images/logo.svg";

const Logo = ({ width, maxWidth, height }) => {
  return (
    <LogoImg style={{ width: width, height: height, maxWidth: maxWidth }} />
    // <Image src={LogoImg} style={{ width: width, maxWidth: maxWidth }} fluid />
  );
};

Logo.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
};

Logo.defaultProps = {
  title: "",
  width: "150px",
  maxWidth: "100%",
};

export default Logo;
