import SUBSCRIPTION_TYPE from "../types/subscrption&paymentTypes";

const initialState = {
  submitting: false,
  error: null,
  data: [],
  subscriptionId: null,
  subscriptionData: null,
  delete: null,
  activeSub: null,
};

export default function challengesReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_PURCHASE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_PURCHASE_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        subscriptionId: action.payload,
      };

    case SUBSCRIPTION_TYPE.GET_SUBSCRIPTION_PURCHASE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        subscriptionData: action.payload,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SUBSCRIPTION_TYPE.DELETE_ACCOUNT_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SUBSCRIPTION_TYPE.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        delete: action.payload,
      };

    case SUBSCRIPTION_TYPE.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_CANCEL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_CANCEL_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case SUBSCRIPTION_TYPE.SUBSCRIPTION_CANCEL_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_ACTIVE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_ACTIVE_SUCCESS:
      return {
        ...state,
        submitting: false,
        activeSub: action.payload,
      };
    case SUBSCRIPTION_TYPE.SUBSCRIPTION_ACTIVE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}

const subscriptionExpireInitialState = {
  is_expire: false,
};

export function subscriptionExpireReducer(
  state = subscriptionExpireInitialState,
  action
) {
  switch (action?.type) {
    case SUBSCRIPTION_TYPE.SUBSCRIPTION_EXPIRED_SUCCESS:
      return {
        ...state,
        is_expire: true,
      };

    case SUBSCRIPTION_TYPE.SUBSCRIPTION_EXPIRED_RESET:
      return {
        ...state,
        is_expire: false,
      };

    default:
      return state;
  }
}
