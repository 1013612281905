const PLAYERSQUAD_TYPES = {
  GET_ALL_PLAYERS_BEGIN: "GET_ALL_PLAYERS_BEGIN",
  GET_ALL_PLAYERS_SUCCESS: "GET_ALL_PLAYERS_SUCCESS",
  GET_ALL_PLAYERS_FAILURE: "GET_ALL_PLAYERS_FAILURE",

  GET_SQUAD_ADD_PLAYERS_BEGIN: "GET_SQUAD_ADD_PLAYERS_BEGIN",
  GET_SQUAD_ADD_PLAYERS_SUCCESS: "GET_SQUAD_ADD_PLAYERS_SUCCESS",
  GET_SQUAD_ADD_PLAYERS_FAILURE: "GET_SQUAD_ADD_PLAYERS_FAILURE",
  GET_SQUAD_ADD_PLAYERS_RESET: "GET_SQUAD_ADD_PLAYERS_RESET",

  GET_ALL_SQUADS_BEGIN: "GET_ALL_SQUADS_BEGIN",
  GET_ALL_SQUADS_SUCCESS: "GET_ALL_SQUADS_SUCCESS",
  GET_ALL_SQUADS_FAILURE: "GET_ALL_SQUADS_FAILURE",

  CREATE_SQUAD: "CREATE_SQUAD",
  CREATE_SQUAD_FAILURE: "CREATE_SQUAD_FAILURE",
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_UPLOAD_FAILURE: "FILE_UPLOAD_FAILURE",

  DELETE_SQUAD: "DELETE_SQUAD",
  DELETE_SQUAD_FAILURE: "DELETE_SQUAD_FAILURE/",

  CREATE_PLAYER: "CREATE_PLAYER",
  CREATE_PLAYER_FAILURE: "CREATE_PLAYER_FAILURE",

  UPDATE_PLAYER: "UPDATE_PLAYER",
  UPDATE_PLAYER_FAILURE: "UPDATE_PLAYER_FAILURE",

  DELETE_PLAYER: "DELETE_PLAYER",
  DELETE_PLAYER_FAILURE: "DELETE_PLAYER_FAILURE/",

  PLAYER_DETAIL: "PLAYER_DETAIL",
  PLAYER_DETAIL_FAILURE: "PLAYER_DETAIL_FAILURE",

  SQUAD_DETAIL: "SQUAD_DETAIL",
  SQUAD_DETAIL_FAILURE: "SQUAD_DETAIL_FAILURE",

  SQUAD_PLAYER_LIST: "SQUAD_PLAYER_LIST",
  SQUAD_PLAYER_LIST_FAILURE: "SQUAD_PLAYER_LIST_FAILURE",

  UPDATE_SQUAD: "UPDATE_SQUAD",
  UPDATE_SQUAD_FAILURE: "UPDATE_SQUAD_FAILURE",

  SQUAD_BEGIN: "SQUAD_BEGIN",
  SQUAD_SUCCESS: "SQUAD_SUCCESS",
  SQUAD_FAILURE: "SQUAD_FAILURE",

  WIN_DAY_BEGIN: "WIN_DAY_BEGIN",
  WIN_DAY_SUCCESS: "WIN_DAY_SUCCESS",
  WIN_DAY_FAILURE: "WIN_DAY_FAILURE",
};

export default PLAYERSQUAD_TYPES;
