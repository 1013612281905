import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link as RouterLink } from "react-router-dom";
import { Navbar, Nav, NavItem, Image } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import Logo from "../Logo";

import routes from "../../routeConfig";

const Sidebar = ({ toggleSidebar, handlerToggleSidebar }) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const items = [];
    routes.children.forEach((item) => {
      if (item.isSidebarMenu) {
        items.push(item);
      }
    });
    setMenuItems(items);
  }, []);
  return (
    <aside
      className={`sidebar ${toggleSidebar && "open"}`}
      onClick={() => handlerToggleSidebar(false)}
    >
      <Navbar.Brand
        as={RouterLink}
        to="/dashboard"
        style={{ borderBottom: " 1px solid #FFFFFF68", height: "74px" }}
        className="pt-1 gap-2 px-lg-2 px-xl-4 d-flex  justify-content-start align-items-center"
      >
        {/* {!toggleSidebar && <Logo width="50px" height="50px" />} */}
        {/* {toggleSidebar && (
          <> */}
        <Logo maxWidth="4rem" height="3.75rem" />
        <div className="fs-20 fw-600 text-white">SuperBetter</div>
        {/* </>
        )} */}
        {/* <div className="fs-20 logo-text fw-600 text-white">SuperBetter</div> */}
      </Navbar.Brand>
      <PerfectScrollbar component="div" className="pt-2 pb-5 pe-3">
        <Nav className="flex-column">
          {menuItems.map(({ key, name, exact, icon, path }, index) => (
            <NavItem
              key={index}
              as={NavLink}
              to={path}
              exact={exact}
              className="ps-4"
            >
              <Image src={icon} className="img-fluid me-3" /> {name}
            </NavItem>
          ))}
        </Nav>
      </PerfectScrollbar>
    </aside>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.profileData,
});

export default connect(mapStateToProps, null)(Sidebar);
