import PLAYERSQUAD_TYPES from "../types/playerSquadTypes";

const initialState = {
  submitting: false,
  creating: false,
  updating: false,
  error: null,
  data: [],
  squads: null,
};

export default function playerSquadReducer(state = initialState, action) {
  switch (action.type) {
    case PLAYERSQUAD_TYPES.GET_ALL_PLAYERS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case PLAYERSQUAD_TYPES.GET_ALL_PLAYERS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case PLAYERSQUAD_TYPES.GET_ALL_PLAYERS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.GET_ALL_SQUADS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case PLAYERSQUAD_TYPES.GET_ALL_SQUADS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case PLAYERSQUAD_TYPES.GET_ALL_SQUADS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.CREATE_SQUAD:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.CREATE_SQUAD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.FILE_UPLOAD:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.FILE_UPLOAD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.CREATE_PLAYER:
      return {
        ...state,
        creating: true,
      };
    case PLAYERSQUAD_TYPES.CREATE_PLAYER_FAILURE:
      return {
        ...state,
        creating: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case PLAYERSQUAD_TYPES.UPDATE_PLAYER:
      return {
        ...state,
        updating: true,
      };
    case PLAYERSQUAD_TYPES.UPDATE_PLAYER_FAILURE:
      return {
        ...state,
        updating: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.DELETE_SQUAD:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.DELETE_SQUAD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.DELETE_PLAYER:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.DELETE_PLAYER_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.PLAYER_DETAIL:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.PLAYER_DETAIL_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.SQUAD_DETAIL:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.SQUAD_DETAIL_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.SQUAD_PLAYER_LIST:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.SQUAD_PLAYER_LIST_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.UPDATE_SQUAD:
      return {
        ...state,
        submitting: false,
      };
    case PLAYERSQUAD_TYPES.UPDATE_SQUAD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.SQUAD_BEGIN:
      return {
        ...state,
        submitting: true,
      };

    case PLAYERSQUAD_TYPES.SQUAD_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        squads: action.payload,
      };
    case PLAYERSQUAD_TYPES.SQUAD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.WIN_DAY_BEGIN:
      return {
        ...state,
        submitting: true,
      };

    case PLAYERSQUAD_TYPES.WIN_DAY_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        winTheDay: action.payload,
      };
    case PLAYERSQUAD_TYPES.WIN_DAY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    default:
      return state;
  }
}

// squad add  players
const accessKeyChallengesInitialState = {
  submitting: false,
  error: null,
  data: null,
};

export function squadAddPlayerReducer(
  state = accessKeyChallengesInitialState,
  action
) {
  switch (action.type) {
    case PLAYERSQUAD_TYPES.GET_SQUAD_ADD_PLAYERS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case PLAYERSQUAD_TYPES.GET_SQUAD_ADD_PLAYERS_SUCCESS:
     
      return {
        ...state,
        submitting: false,
        error: null,
        data: !state.data
          ? action.payload
          : {
              ...state?.data,
              data: {
                ...state?.data?.data,
                result: [
                  ...state?.data?.data?.result,
                  ...action?.payload?.data?.result,
                ],
              },
            },
      };

    case PLAYERSQUAD_TYPES.GET_SQUAD_ADD_PLAYERS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case PLAYERSQUAD_TYPES.GET_SQUAD_ADD_PLAYERS_RESET:
      return {
        ...state,
        submitting: false,
        error: null,
        data: null,
      };

    default:
      return state;
  }
}
