import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import BlankLayout from "./BlankLayout";

function MasterLayout({ children, isAuthenticated }) {
  const location = useLocation();

  const { pathname: path = "" } = location;
  const arryId = path?.split("/");
  const page = arryId[arryId.length - 1];

  const Authenticaed =
    page !== "subscribe" && page !== "Stripe" && isAuthenticated ? true : false;

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;

    document.body.appendChild(script);

   
  }, []);

  const [appHeader, setAppHeader] = useState(Authenticaed);
  const Layout = Authenticaed ? DefaultLayout : BlankLayout;
  return (
    <Layout setAppHeader={setAppHeader} appHeader={appHeader}>
      {children}
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(MasterLayout);
