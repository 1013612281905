import ANALYSIST_TYPES from "../types/analysistTypes";

const initialState = {
  submitting: false,
  error: null,
  data: []
};

export default function analysistReducer(state = initialState, action) {
  switch (action.type) {
    case ANALYSIST_TYPES.REPORT_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case ANALYSIST_TYPES.REPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload
      };

    case ANALYSIST_TYPES.REPORT_FAILURE:
        return {
          ...state,
          submitting: false,
          error:
            action.payload && action.payload.error ? action.payload.error : null,
        };

    default:
      return state;
  }
}