import jwt_decode from "jwt-decode";
import axios from "axios";
import _ from "lodash-es";
import { store } from "../redux/store";
import { showSnack } from "../redux/actions/alertActions";
// import { logout } from "../redux/actions/authActions";
import { BASE_URL, ENDPOINTS } from "./constants";
import {
  accountIsNotVerifiedSuccess,
  logoutSuccess,
  refreshTokenSuccess,
} from "../redux/actions/authActions";
import { prePath } from "../utils";
import { subscriptionExpireSuccess } from "../redux/actions/subscription&payment";
import storage from "redux-persist/lib/storage";
import { clearStorage } from "../redux/actions/clearStorage";

const options = {
  variant: "error",
};

const extractErrorMessage = (data) => {
  if (_.isString(data)) {
    return data;
  }

  if (data.error) {
    return data.error;
  }
  if (data?.data?.message) {
    return data?.data?.message;
  }

  return "Something went wrong";
};

export const errorHandler = ({ status, data }) => {
  // const state = store.getState();
  // const {
  //   auth: { token },
  // } = state;
  switch (status) {
    case 401:
      store.dispatch(
        showSnack({
          message: extractErrorMessage(data),
          options: { ...options, preventDuplicate: true },
        })
      );
      storage.removeItem("persist:root");
      localStorage.clear();
      store.dispatch(logoutSuccess());
      store.dispatch(clearStorage());
      break;
    case 400:
    case 409:
      store.dispatch(
        showSnack({
          message: extractErrorMessage(data),
          options: { ...options, preventDuplicate: true },
        })
      );
      if (data?.data?.details?.is_email_verified === false) {
        store.dispatch(accountIsNotVerifiedSuccess());
      }
      break;
    case 403:
      store.dispatch(
        showSnack({
          message: extractErrorMessage(data),
          options: { ...options, preventDuplicate: true },
        })
      );
      if (data?.is_subscribed === false) {
        store.dispatch(subscriptionExpireSuccess());
      }
      break;
    case 422:
      store.dispatch(
        showSnack({
          message: extractErrorMessage(data),
          options: { ...options, preventDuplicate: true },
        })
      );
      break;
    case 404:
      store.dispatch(
        showSnack({
          message: extractErrorMessage(data) || "Resource not found",
          options: { ...options, preventDuplicate: true },
        })
      );
      break;
    default:
      store.dispatch(
        showSnack({
          message: "Something went wrong",
          options: { ...options, preventDuplicate: true },
        })
      );
      break;
  }
};

export const getToken = async () => {
  const state = store.getState();
  const {
    auth: { token },
  } = state;

  if (!token || !token.access.token || !token.refresh.token) {
    return null;
  }

  const decodedToken = jwt_decode(token.access.token);
  const date = new Date(0);
  date.setUTCSeconds(decodedToken.exp);
  if (date.valueOf() < new Date().valueOf()) {
    try {
      const result = await axios.post(
        `${BASE_URL}/${ENDPOINTS.REFRESH_TOKEN}`,
        { refresh_token: token.refresh.token }
      );
      store.dispatch(refreshTokenSuccess({ token: result.data.data }));
      return result.data.data.access.token;
    } catch (err) {
      errorHandler(err.response);
    }
  }

  return token.access.token;
};

export const uniqueValues = (arr, keyProps) => {
  const kvArray = arr.map((entry) => {
    const key = keyProps.map((k) => entry.headers[k]).join("|");
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
};
export function getImageUrl(appendString) {
  return appendString ? `${prePath + appendString}` : "";
}
// export const checkImageResolution = (files) => {
//   return new Promise((resolve, reject) => {
//     var reader = new FileReader();
//     reader.readAsDataURL(files);
//     reader.onload = async function (e) {
//       var image = new Image();
//       image.src = e.target.result;
//       image.onload = async function () {
//         var height = this.height;
//         var width = this.width;
//         if (height > 400 && width > 400) {
//           return resolve(false);
//         } else {
//           return resolve(true);
//         }
//       };
//     };
//   });
// };
