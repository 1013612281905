const SUBSCRIPTION_TYPE = {
  GET_SUBSCRIPTION_BEGIN: "GET_ALL_CHALLENGES_BEGIN",
  GET_SUBSCRIPTION_SUCCESS: "GET_ALL_CHALLENGES_SUCCESS",
  GET_SUBSCRIPTION_FAILURE: "GET_ALL_CHALLENGES_FAILURE",

  GET_SUBSCRIPTION_PURCHASE_BEGIN: "GET_SUBSCRIPTION_PURCHASE_BEGIN",
  GET_SUBSCRIPTION_PURCHASE_SUCCESS: "GET_SUBSCRIPTION_PURCHASE_SUCCESS",
  GET_SUBSCRIPTION_PURCHASE_FAILURE: "GET_SUBSCRIPTION_PURCHASE_FAILURE",

  SUBSCRIPTION_UPDATE_BEGIN: "SUBSCRIPTION_UPDATE_BEGIN",
  SUBSCRIPTION_UPDATE_SUCCESS: "SUBSCRIPTION_UPDATE_SUCCESS",
  SUBSCRIPTION_UPDATE_FAILURE: "SUBSCRIPTION_UPDATE_FAILURE",

  SUBSCRIPTION_BEGIN: "SUBSCRIPTION_BEGIN",
  SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
  SUBSCRIPTION_FAILURE: "SUBSCRIPTION_FAILURE",

  SUBSCRIPTION_EXPIRED_SUCCESS: "SUBSCRIPTION_EXPIRED_SUCCESS",
  SUBSCRIPTION_EXPIRED_RESET: "SUBSCRIPTION_FAILURE_RESET",

  DELETE_ACCOUNT_BEGIN: "DELETE_ACCOUNT_BEGIN",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_FAILURE: "DELETE_ACCOUNT_FAILURE",

  SUBSCRIPTION_CANCEL_BEGIN: "SUBSCRIPTION_CANCEL_BEGIN",
  SUBSCRIPTION_CANCEL_SUCCESS: "SUBSCRIPTION_CANCEL_SUCCESS",
  SUBSCRIPTION_CANCEL_FAILURE: "SUBSCRIPTION_CANCEL_FAILURE",

  SUBSCRIPTION_ACTIVE_BEGIN: "SUBSCRIPTION_ACTIVE_BEGIN",
  SUBSCRIPTION_ACTIVE_SUCCESS: "SUBSCRIPTION_ACTIVE_SUCCESS",
  SUBSCRIPTION_ACTIVE_FAILURE: "SUBSCRIPTION_ACTIVE_FAILURE",
};

export default SUBSCRIPTION_TYPE;
