import React from "react";
import { get } from "lodash-es";
import paths from "path";
import ControlImg from "./assets/images/control.svg";
import LibraryImg from "./assets/images/library.svg";
import PlayersImg from "./assets/images/player.svg";
import ActiveChallengesImg from "./assets/images/active-challenge.svg";
import AnalyticsImg from "./assets/images/analytics.svg";

import { hierarchize } from "./utils/hierarchical";

const keyName = "key";
const pathName = "path";
const uniqueKeyName = "uniqueKey";

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + "." + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, "");
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: "root",
    name: "Root",
    path: "/",
    exact: true,
    component: React.lazy(() => import("./containers/ControlCenter")),
    children: [
      {
        key: "login",
        name: "Login",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/Login/index")),
        isSidebarMenu: false,
      },
      {
        key: "redirect",
        name: "Redirect",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/RedirectToHome")),
        isSidebarMenu: false,
      },
      {
        key: "forgot-password",
        name: "Forgot Password",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/ForgotPassword")),
        isSidebarMenu: false,
      },
      {
        key: "create-password/:resetPasswordToken",
        name: "create-password",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/ResetPassword")),
        isSidebarMenu: false,
      },
      {
        key: "signup",
        name: "Sign Up",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/SignUp/index")),
        isSidebarMenu: false,
      },
      {
        key: "payment-tab",
        name: "Payment Tab",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/Stripe/index")),
        isSidebarMenu: false,
        appHeader: true,
      },
      {
        key: "subscribe",
        name: "Subscribe",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/Onboarding/Subscribe")
        ),
        isSidebarMenu: false,
        appHeader: true,
      },
      {
        key: "stripe",
        name: "Stripe",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/Onboarding/stripe/stripe")
        ),
        isSidebarMenu: false,
        appHeader: false,
      },
      {
        key: "/auth/email-verification/:id",
        name: "EmailVerification",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/EmailVerification")
        ),
        isSidebarMenu: false,
        appHeader: false,
      },
      {
        key: "onboarding",
        name: "Onboarding",
        isPublic: true,
        isHidden: true,
        exact: true,
        isSidebarMenu: false,
        appHeader: true,
        children: [
          {
            key: "personal-details",
            name: "Personal Details",
            isPublic: true,
            isHidden: true,
            exact: true,
            component: React.lazy(() =>
              import("./containers/Auth/Onboarding/PersonalDetails")
            ),
            isSidebarMenu: false,
          },
          {
            key: "gather-info",
            name: "Gather Info",
            isPublic: true,
            isHidden: true,
            exact: true,
            component: React.lazy(() =>
              import(
                "./containers/Auth/Onboarding/hostDetails/gatherInformation"
              )
            ),
            isSidebarMenu: false,
          },
        ],
      },
      {
        key: "dashboard",
        name: "Dashboard",
        exact: true,
        component: React.lazy(() => import("./containers/ControlCenter")),
        isSidebarMenu: true,
        icon: ControlImg,
      },
      {
        key: "squads",
        name: "Create Squads",
        exact: false,
        component: React.lazy(() => import("./containers/PlayersAndSquad")),
        isSidebarMenu: true,
        icon: PlayersImg,
      },
      {
        key: "library",
        name: "Host Challenges",
        exact: false,
        component: React.lazy(() => import("./containers/Library")),
        isSidebarMenu: true,
        icon: LibraryImg,
      },

      {
        key: "active-challenges",
        name: "Monitor Progress",
        exact: false,
        component: React.lazy(() => import("./containers/Challenges")),
        isSidebarMenu: true,
        icon: ActiveChallengesImg,
      },
      {
        key: "analytics",
        name: "Squad Analytics",
        exact: false,
        component: React.lazy(() => import("./containers/Analytics")),
        isSidebarMenu: true,
        icon: AnalyticsImg,
      },
      {
        key: "account",
        name: "Account",
        isHidden: true,
        component: React.lazy(() => import("./containers/Account")),
        isSidebarMenu: false,
      },
    ],
  },
  null,
  pathGenerator
);

export default routeConfig;
