import {
  loginBegin,
  loginFailure,
  loginSuccess,
  loginSteps,
  registerBegin,
  registerFailure,
  registerSuccess,
  setSubmitting,
  getProfileDetailsBegin,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  setauthentication,
  emailVerificationBegin,
  emailVerificationSuccess,
  emailVerificationFailure,
  getResentEmailBegin,
  getResentEmailSuccess,
  getResentEmailFailure,
  socialLoginBegin,
  socialLoginSuccess,
  socialLoginFailure,
  logoutBegin,
  logoutSuccess,
  logoutFailure,
} from "../actions/authActions";
import { showSnack, resetSnack } from "../actions/alertActions";

import ENDPOINTS, { GET, POST } from "../../services/requests";
import { clearStorage } from "../actions/clearStorage";

export function AuthenticationConfirm(data) {
  return async (dispatch) => {
    dispatch(setauthentication(true));
  };
}

export function performLogin({ email, password }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(loginBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.LOGIN,
        data: {
          email,
          password,
        },
      });

      if (result.data.details) {
        if (result?.data?.details?.host?.onboarding_step === "completed") {
          dispatch(
            loginSuccess({
              token: result.data.details.tokens,
              user: result.data.details.host,
            })
          );
        } else {
          dispatch(
            loginSteps({
              token: result.data.details.tokens,
              user: result.data.details.host,
            })
          );
        }

        dispatch(
          showSnack({
            message: "Logged in successfully!",
            options: {
              variant: "success",
            },
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(loginFailure(error));
      return false;
    }
  };
}

export function proceedEmailVerification(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(emailVerificationBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.EMAIL_VERIFICATION(data),
      });
      if (result) {
        dispatch(emailVerificationSuccess(result));

        dispatch(
          loginSteps({
            token: result?.data?.result.tokens,
            user: result?.data?.result.host,
          })
        );
        dispatch(
          showSnack({
            message: "Email Verified Successfully",
            options: {
              variant: "success",
            },
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(emailVerificationFailure(error));
      return false;
    }
  };
}

export function performRegister(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(registerBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.REGISTER,
        data: data,
      });
      if (result?.data?.details?.host) {
        dispatch(
          showSnack({
            message:
              "Registered successfully, Please verify your account to login!",
            options: {
              variant: "success",
            },
          })
        );
        dispatch(
          registerSuccess({
            token: result?.data?.details?.tokens,
            user: result?.data?.details?.host,
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(registerFailure(error));
      return false;
    }
  };
}

export function performForgotPassword(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(setSubmitting(true));
    try {
      const result = await POST({ url: ENDPOINTS.FORGOT_PASSWORD, data });
      dispatch(
        showSnack({
          message: result.data.message,
          options: {
            variant: "success",
          },
        })
      );
      return result.data;
    } catch (error) {
      dispatch(setSubmitting(false));
      dispatch(registerFailure(error));
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performResetPassword(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(setSubmitting(true));
    try {
      const result = await POST({
        url: ENDPOINTS.RESET_PASSWORD,
        data,
      });
      dispatch(
        showSnack({
          message: result.data.message,
          options: {
            variant: "success",
          },
        })
      );
      return true;
    } catch (error) {
      dispatch(setSubmitting(false));
      dispatch(registerFailure(error));
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performGetProfileDetails(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(getProfileDetailsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_PROFILE });
      dispatch(getProfileDetailsSuccess(result.data?.details?.host));

      return result;
    } catch (error) {
      dispatch(getProfileDetailsFailure(error));
      return false;
    }
  };
}

export function resendEmailVerification(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(getResentEmailBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.GET_RESEND_VERIFICATION,
        data,
      });
      dispatch(
        showSnack({
          message: result?.data?.message,
          options: {
            variant: "success",
          },
        })
      );
      dispatch(getResentEmailSuccess(result.data));

      return true;
    } catch (error) {
      dispatch(getResentEmailFailure(error));
      return false;
    }
  };
}

export function socailLogin(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(socialLoginBegin());
    try {
      const result = await POST({ url: ENDPOINTS.SOCIAL_LOGIN, data });
      dispatch(socialLoginSuccess(result.data));

      if (result?.data?.details?.host?.onboarding_step === "completed") {
        dispatch(
          loginSuccess({
            token: result.data.details.tokens,
            user: result.data.details.host,
          })
        );
      } else {
        dispatch(
          loginSteps({
            token: result.data.details.tokens,
            user: result.data.details.host,
          })
        );
      }
      return result.data;
    } catch (error) {
      dispatch(socialLoginFailure(error));
      return false;
    }
  };
}

export function performLogout({ refresh_token }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(logoutBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.LOGOUT,
        data: {
          refresh_token,
        },
      });

      if (result.success) {
        dispatch(logoutSuccess());
        dispatch(
          showSnack({
            message: result?.data?.message,
            options: {
              variant: "success",
            },
          })
        );
        setTimeout(() => {
          dispatch(clearStorage());
        }, 500);
      }

      return result;
    } catch (error) {
      dispatch(logoutFailure(error));
      return false;
    }
  };
}
