import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCrossIcon from "../../assets/images/modalCross";
import mainSubscription from "../../assets/images/mainSubscription.svg"

function MainSubscriptionModal({
  handleConfirm,
  handleClose,
  show,
  heading,
  confirmName,
  para,
  logo=''
}) {
  return (
    <>
      <Modal centered show={show} onHide={handleClose} animation={false}>
          <div onClick={handleClose}>
          <ModalCrossIcon className="modal-cross pointer"/>
          </div>
          <Modal.Title className="d-flex flex-column align-items-center mt-4  ">
         {  logo&& <img width='80px' src={mainSubscription} alt='logo' className='mb-3'/>}
            {heading}
          </Modal.Title>
        <div className="d-flex flex-column align-items-center">
          <Modal.Body className="text-center modal-para">{para}</Modal.Body>
          <div className="d-flex mb-4">
            <Button variant="primary" className="button-padding" onClick={handleConfirm}>
              {confirmName}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MainSubscriptionModal;