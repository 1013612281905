const CHALLENGES_TYPES = {
  GET_ALL_CHALLENGES_BEGIN: "GET_ALL_CHALLENGES_BEGIN",
  GET_ALL_CHALLENGES_SUCCESS: "GET_ALL_CHALLENGES_SUCCESS",
  GET_ALL_CHALLENGES_FAILURE: "GET_ALL_CHALLENGES_FAILURE",

  GET_ALL_SAVED_CHALLENGES_BEGIN: "GET_ALL_SAVED_CHALLENGES_BEGIN",
  GET_ALL_SAVED_CHALLENGES_SUCCESS: "GET_ALL_SAVED_CHALLENGES_SUCCESS",
  GET_ALL_SAVED_CHALLENGES_FAILURE: "GET_ALL_SAVED_CHALLENGES_FAILURE",

  GET_ALL_HOSTED_CHALLENGES_BEGIN: "GET_ALL_HOSTED_CHALLENGES_BEGIN",
  GET_ALL_HOSTED_CHALLENGES_SUCCESS: "GET_ALL_HOSTED_CHALLENGES_SUCCESS",
  GET_ALL_HOSTED_CHALLENGES_FAILURE: "GET_ALL_HOSTED_CHALLENGES_FAILURE",

  CHALLENGE_DETAIL_BEGIN: "CHALLENGE_DETAIL_BEGIN",
  CHALLENGE_DETAIL_SUCCESS: "CHALLENGE_DETAIL_SUCCESS",
  CHALLENGE_DETAIL_FAILURE: "CHALLENGE_DETAIL_FAILURE",

  CREATE_CHALLENGES_BEGIN: "CREATE_CHALLENGES_BEGIN",
  CREATE_CHALLENGES_SUCCESS: "CREATE_CHALLENGES_SUCCESS",
  CREATE_CHALLENGES_FAILURE: "CREATE_CHALLENGES_FAILURE",

  UPDATE_CHALLENGES_BEGIN: "UPDATE_CHALLENGES_BEGIN",
  UPDATE_CHALLENGES_SUCCESS: "UPDATE_CHALLENGES_SUCCESS",
  UPDATE_CHALLENGES_FAILURE: "UPDATE_CHALLENGES_FAILURE",

  DELETE_CHALLENGES_BEGIN: "DELETE_CHALLENGES_BEGIN",
  DELETE_CHALLENGES_SUCCESS: "DELETE_CHALLENGES_SUCCESS",
  DELETE_CHALLENGES_FAILURE: "DELETE_CHALLENGES_FAILURE",

  SB_CHALLENGES_BEGIN: "SB_CHALLENGES_BEGIN",
  SB_CHALLENGES_SUCCESS: "SB_CHALLENGES_SUCCESS",
  SB_CHALLENGES_FAILURE: "SB_CHALLENGES_FAILURE",

  SUGGESTIONS_BEGIN: "SUGGESTIONS_BEGIN",
  SUGGESTIONS_SUCCESS: "SUGGESTIONS_SUCCESS",
  SUGGESTIONS_FAILURE: "SUGGESTIONS_FAILURE",

  GET_ALL_CHALLENGE_PLAYERS_BEGIN: "GET_ALL_CHALLENGE_PLAYERS_BEGIN",
  GET_ALL_CHALLENGE_PLAYERS_SUCCESS: "GET_ALL_CHALLENGE_PLAYERS_SUCCESS",
  GET_ALL_CHALLENGE_PLAYERS_FAILURE: "GET_ALL_CHALLENGE_PLAYERS_FAILURE",
};

export default CHALLENGES_TYPES;
