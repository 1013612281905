const AUTH_TYPES = {
  RESET_STATE: "RESET_STATE",

  UPDATE_USER_DATA: "UPDATE_USER_DATA",

  LOGIN_BEGIN: "LOGIN_BEGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_STEPS: "LOGIN_STEPS",
  LOGIN_NO_2FA_SUCCESS: "LOGIN_NO_2FA_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  REGISTER_BEGIN: "REGISTER_BEGIN",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  RESEND_LOGIN_OTP_BEGIN: "RESEND_LOGIN_OTP_BEGIN",
  RESEND_LOGIN_OTP_SUCCESS: "RESEND_LOGIN_OTP_SUCCESS",
  RESEND_LOGIN_OTP_FAILURE: "RESEND_LOGIN_OTP_FAILURE",

  VERIFY_LOGIN_OTP_BEGIN: "VERIFY_LOGIN_OTP_BEGIN",
  VERIFY_LOGIN_OTP_SUCCESS: "VERIFY_LOGIN_OTP_SUCCESS",
  VERIFY_LOGIN_OTP_FAILURE: "VERIFY_LOGIN_OTP_FAILURE",

  REFRESH_TOKEN_BEGIN: "REFRESH_TOKEN_BEGIN",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

  CHANGE_PASSWORD_BEGIN: "CHANGE_PASSWORD_BEGIN",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  VERIFY_ADMIN_SESSION_BEGIN: "VERIFY_ADMIN_SESSION_BEGIN",
  VERIFY_ADMIN_SESSION_SUCCESS: "VERIFY_ADMIN_SESSION_SUCCESS",
  VERIFY_ADMIN_SESSION_FAILURE: "VERIFY_ADMIN_SESSION_FAILURE",

  VERIFY_ADMIN_SESSION_OTP_BEGIN: "VERIFY_ADMIN_SESSION_OTP_BEGIN",
  VERIFY_ADMIN_SESSION_OTP_SUCCESS: "VERIFY_ADMIN_SESSION_OTP_SUCCESS",
  VERIFY_ADMIN_SESSION_OTP_FAILURE: "VERIFY_ADMIN_SESSION_OTP_FAILURE",

  SET_SUBMITTING: "SET_SUBMITTING",
  SET_IDLE_TIMEOUT: "SET_SESSION_IDLE_TIMEOUT",

  LOGOUT_BEGIN: "LOGOUT_BEGIN",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  GET_PROFILE_DETAILS_BEGIN: "GET_PROFILE_DETAILS_BEGIN",
  GET_PROFILE_DETAILS_SUCCESS: "GET_PROFILE_DETAILS_SUCCESS",
  GET_PROFILE_DETAILS_FAILURE: "GET_PROFILE_DETAILS_FAILURE",

  AUTHENTICATION_SET: "AUTHENTICATION_SET",

  EMAIL_VERIFICATION_BEGIN: "EMAIL_VERIFICATION_BEGIN",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  EMAIL_VERIFICATION_FAILURE: "EMAIL_VERIFICATION_FAILURE",

  SOCIAL_LOGIN_BEGIN: "SOCIAL_LOGIN_BEGIN",
  SOCIAL_LOGIN_SUCCESS: "SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_FAILURE: "SOCIAL_LOGIN_FAILURE",
  ACCOUNT_NOT_VERIFIED_SUCCESS: "ACCOUNT_NOT_VERIFIED_SUCCESS",
  ACCOUNT_NOT_VERIFIED_RESET: "ACCOUNT_NOT_VERIFIED_RESET",
};

export default AUTH_TYPES;
