const LIBRARY_ACTIVITY_TYPES = {
  LIBRARY_ACTIVITY_BEGIN: "LIBRARY_ACTIVITY_BEGIN",
  LIBRARY_ACTIVITY_SUCCESS: "LIBRARY_ACTIVITY_SUCCESS",
  LIBRARY_ACTIVITY_FAILURE: "LIBRARY_ACTIVITY_FAILURE",
  LIBRARY_CHALLENGES_BEGIN: "LIBRARY_CHALLENGES_BEGIN",

  
  LIBRARY_REORDER_ACTIVITY_BEGIN: 'LIBRARY_REORDER_ACTIVITY_BEGIN',
  LIBRARY_REORDER_ACTIVITY_SUCCESS: 'LIBRARY_REORDER_ACTIVITY_SUCCESS',
  LIBRARY_REORDER_ACTIVITY_FAILURE: 'LIBRARY_REORDER_ACTIVITY_FAILURE',

  LIBRARY_CHALLENGES_SUCCESS: "LIBRARY_CHALLENGES_SUCCESS",
  LIBRARY_CHALLENGES_FAILURE: "LIBRARY_CHALLENGES_FAILURE",

  CHALLENGES_OVERVIEW_BEGIN: "CHALLENGES_OVERVIEW_BEGIN",
  CHALLENGES_OVERVIEW_SUCCESS: "CHALLENGES_OVERVIEW_SUCCESS",
  CHALLENGES_OVERVIEW_FAILURE: "CHALLENGES_OVERVIEW_FAILURE",

  DELETE_ACTIVTIY_BEGIN: "DELETE_ACTIVTIY_BEGIN",
  DELETE_ACTIVTIY_SUCCESS: "DELETE_ACTIVTIY_SUCCESS",
  DELETE_ACTIVTIY_FAILURE: "DELETE_ACTIVTIY_FAILURE",

  HOST_SAVE_BEGIN: "HOST_SAVE_BEGIN",
  HOST_SAVE_SUCCESS: "HOST_SAVE_SUCCESS",
  HOST_SAVE_FAILURE: "HOST_SAVE_FAILURE",

  SEND_INVITATIONS_BEGIN: "SEND_INVITATIONS_BEGIN",
  SEND_INVITATIONS_SUCCESS: "SEND_INVITATIONS_SUCCESS",
  SEND_INVITATIONS_FAILURE: "SEND_INVITATIONS_FAILURE",
};

export default LIBRARY_ACTIVITY_TYPES;
