import CHALLENGES_TYPES from "../types/challengesTypes";

const initialState = {
  submitting: false,
  error: null,
  data: [],
  update: null,
};

export default function challengesReducer(state = initialState, action) {
  switch (action.type) {
    case CHALLENGES_TYPES.GET_ALL_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.GET_ALL_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case CHALLENGES_TYPES.GET_ALL_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // CHALLENEG DETAILS
    case CHALLENGES_TYPES.CHALLENGE_DETAIL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.CHALLENGE_DETAIL_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case CHALLENGES_TYPES.CHALLENGE_DETAIL_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case CHALLENGES_TYPES.CREATE_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.CREATE_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case CHALLENGES_TYPES.CREATE_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case CHALLENGES_TYPES.UPDATE_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.UPDATE_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        update: action.payload,
      };

    case CHALLENGES_TYPES.UPDATE_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case CHALLENGES_TYPES.SB_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.SB_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        sbChallenge: action.payload,
      };

    case CHALLENGES_TYPES.SB_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case CHALLENGES_TYPES.SUGGESTIONS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.SUGGESTIONS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        controlSugesstion: action.payload,
      };

    case CHALLENGES_TYPES.SUGGESTIONS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case CHALLENGES_TYPES.DELETE_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.DELETE_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case CHALLENGES_TYPES.DELETE_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    default:
      return state;
  }
}
export function hostedChallengesReducer(state = initialState, action) {
  switch (action.type) {
    case CHALLENGES_TYPES.GET_ALL_HOSTED_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.GET_ALL_HOSTED_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case CHALLENGES_TYPES.GET_ALL_HOSTED_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
export function savedChallengesReducer(state = initialState, action) {

  switch (action.type) {
    case CHALLENGES_TYPES.GET_ALL_SAVED_CHALLENGES_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.GET_ALL_SAVED_CHALLENGES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload.result,
      };

    case CHALLENGES_TYPES.GET_ALL_SAVED_CHALLENGES_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}

const challengePlayersInitialState = {
  submitting: false,
  error: null,
  data: null,
  update: null,
};

export function challengePlayersReducer(
  state = challengePlayersInitialState,
  action
) {
  switch (action.type) {
    case CHALLENGES_TYPES.GET_ALL_CHALLENGE_PLAYERS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case CHALLENGES_TYPES.GET_ALL_CHALLENGE_PLAYERS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        data: action.payload,
      };

    case CHALLENGES_TYPES.GET_ALL_CHALLENGE_PLAYERS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
